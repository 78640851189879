@use "sass:map";

@mixin dalia-device-information-tab-theme($theme) {
  $text-secondary: map.get($theme, al-color-text-secondary);
  $grey100: map.get($theme, al-color-palette-grey100);

  .loading .iot-platform-ui-tab-group__container {
    align-self: center;
  }
  
  .dalia-device-communication-settings,
  .dalia-device-communication-settings-form {
    &__section {
      &_radio-bands {
        border-color: $grey100 !important;

        &_label {
          color: $text-secondary;
        }

        .mat-mdc-checkbox .mdc-checkbox {
          flex: 0 0 16px;
          width: 16px;
          height: 16px;

          .mdc-checkbox__background {
            height: 16px;
            width: 16px;
          }
        }
      }
    }

    .iot-platform-ui-infos-display-card-property {
      &__value {
        span:first-child {
          min-width: 60px;
        }
      }
    }
  }

  .dalia-device-communication-settings-form {
    .iot-platform-ui-infos-display-card-property__value {
      padding: 2px;
    }
  }

  .dalia-alarm-card {
    .iot-platform-ui-infos-display-card-property {
      &__value {
        span:first-child {
          min-width: 40px;
        }
      }
    }
  }
}
