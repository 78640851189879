/* Override typography
@use '@angular/material' as mat;

$font-family: 'Roboto';

$custom-typography: mat.m2-define-typography-config(
        $font-family: $font-family,
        $button: mat.m2-define-typography-level(
                $font-family: $font-family,
                $font-weight: 500,
                $font-size: 12px,
                $letter-spacing: normal
        )
);

@include mat.all-component-typographies($custom-typography);
@include mat.elevation-classes();
@include mat.app-background();
*/
