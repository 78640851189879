@mixin dalia-device-template-information-tab-theme($theme) {

  .loading .iot-platform-ui-tab-group__container {
    align-self: center;
  }

  .dalia-device-template-info-tab {
    &__overview-card {
      background-color: transparent !important;
      .overview-card,
      .iot-platform-ui-overview-card {
        box-shadow: none !important;
        padding: 0 !important;
        background-color: transparent !important;
      }

      .device-template-description {
        .overview-card__property-label {
          max-width: 22% !important;
        }

        .overview-card__property-value {
          white-space: pre-wrap !important;
          word-break: break-word !important;
          line-height: 23px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
