@use '@angular/material' as mat;
@use "sass:map";

@mixin dalia-ui-devices-energy-mode-battery-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $yellow300: map.get($theme, al-color-palette-yellow300);
  $green200: map.get($theme, al-color-palette-green200);
  $orange300: map.get($theme, al-color-palette-orange300);
  $red300: map.get($theme, al-color-palette-red300);

  $yellow500: map.get($theme, al-color-palette-yellow500);
  $green400: map.get($theme, al-color-palette-green400);
  $orange400: map.get($theme, al-color-palette-orange400);
  $red400: map.get($theme, al-color-palette-red400);

  .dalia-ui-devices-energy-mode-battery {
    &--fpm {
      background-color: $green200;
      border-color: $green400 !important;
    }
    &--eco1 {
      background-color: $yellow300;
      border-color: $yellow500 !important;
    }
    &--eco2 {
      background-color: $orange300;
      border-color: $orange400 !important;
    }
    &--psm {
      background-color: $red300;
      border-color: $red400 !important;
    }
  }
}
