@use '@angular/material' as mat;
@use "sass:map";
@mixin dalia-device-list-tab-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $stroke-primary: map.get($theme, al-color-stroke-primary);
  $text-error: map.get($theme, al-color-text-error);
  $bg-layer-error: map.get($theme, al-color-bg-layer-error);
  $red300: map.get($theme, al-color-palette-red300);

  .device-tank-list-tab,
  .device-modbus-request-table-list-tab {

    .iot-platform-ui-overview-card {
      box-shadow: none !important;
    }

    .iot-platform-ui-list-view {
      background: #fff;
    }

    .iot-platform-ui-list-view-items {

      .iot-platform-ui-list-view-items__item {

        &_avatar {
          border-color: #ffffff !important;
          color: $accent !important;
          background: $primary;

          &.text-error {
            color: $text-error !important;
            background: $bg-layer-error;
          }
        }

        &.pending,
        &.published,
        &.deleted,
        &.has-configuration-error {
          span.mdc-list-item__content:before {
            content: "";
            border: 1px solid #ffffff;
            width: 8px;
            height: 43px;
            border-radius: 3px;
            margin-left: -17px;
            margin-top: -2px;
            position: absolute;
          }

          span.mdc-list-item__content {
            margin-left: 5px;
          }
        }

        &.pending {
          span.mdc-list-item__content:before {
            @extend .bg-orange100;
          }
        }

        &.published {
          span.mdc-list-item__content:before {
            @extend .bg-blue100;
          }
        }

        &.deleted {
          span.mdc-list-item__content:before {
            @extend .bg-red300;
          }
        }

        &.has-configuration-error {
          span.mdc-list-item__content:before {
            background: $red300 !important;
          }
        }

        &.mat-mdc-list-item {
          &--selected {
            &::before {
              background-color: var(--mdc-list-list-item-hover-state-layer-color);
              opacity: var(--mdc-list-list-item-hover-state-layer-opacity);
            }
          }
        }
      }
    }
  }

  .dalia-device-tank-config-form__variable-section-title,
  .device-modbus-request-table-config-form__variable-section-title {
    &::after {
      border-color: $stroke-primary !important;
    }
  }
}
