@use "sass:map";

@mixin dalia-device-energy-mode-form-theme($theme) {
  $purple200: map.get($theme, al-color-palette-purple200);

  .dalia-device-energy-mode-form {
    .mat-mdc-radio-button {
      width: 100%;

      .mdc-form-field {
        width: 100%;

        .mdc-label {
          width: 100%;
        }
      }
    }

    .iot-platform-ui-infos-display-card-property {
      min-width: 160px !important;
    }

    &__radio-group {
      &_additional-options {
        border-top-color: $purple200 !important;
      }
    }
  }
}
