@use "sass:map";

@mixin dalia-device-variable-form($theme) {
  $purple50: map.get($theme, al-color-palette-purple50);
  $purple200: map.get($theme, al-color-palette-purple200);
  $wgreen100: map.get($theme, al-color-palette-wgreen100);

  .dalia-device-variable-form {
    &__section {
      &_device-event {

        &__toggle {
          background-color: $purple50;
        }

        .dashed-border-bottom {
          .mat-mdc-form-field::after {
            content: "";
            border: 1px dashed $purple200;
            width: 102%;
            margin-bottom: 16px;
            margin-top: 10px;
          }

          .iot-platform-ui-form-field__undo-button {
            top: -1rem;
          }
        }

        &--toggle-checked {
          .dalia-device-variable-form__section_device-event__toggle {
            background-color: $wgreen100 !important;
          }

          .dashed-border-bottom .mat-mdc-form-field::after {
            border-color: $wgreen100 !important;
          }
        }
      }

      .iot-platform-ui-form-field__alarmHysteresis {
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
          line-height: 18px;
        }
      }
    }
  }
}
