@use '@angular/material' as mat;
@use "sass:map";

@mixin dalia-device-info-form-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $text-primary: map.get($theme, al-color-text-primary);
  $text-white: map.get($theme, al-color-text-white);

  .dalia-device-info-form-dialog {
    color: $text-primary;

    &__image {
      background: #e9eff7;

      .mat-icon {
        min-width: 10rem;
        min-height: 10rem;

        svg {
          fill: $text-white;

          path {
            stroke: $accent;
          }
        }
      }
    }

    iot-platform-ui-image-selector {
      .preview {
        display: flex;
        justify-content: center;
      }

      .container {
        width: 100%;
        height: 100%;
      }
    }
  }
}
