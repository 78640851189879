@use "sass:map";

@mixin dalia-device-template-overview-theme($theme) {
  $wgreen700: map.get($theme, al-color-palette-wgreen700);
  $wgreen100: map.get($theme, al-color-palette-wgreen100);

  .dalia-device-template-overview {
    &__page-title {
      color: $wgreen700;
      background: $wgreen100;
    }
  }
}
