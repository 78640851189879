@use "sass:map";

@mixin dalia-device-overview-theme($theme) {
  $text-white: map.get($theme, al-color-text-white);
  $orange100: map.get($theme, al-color-palette-orange100);
  $orange700: map.get($theme, al-color-palette-orange700);
  $blue: map.get($theme, al-color-brand-blue);
  $blue100: map.get($theme, al-color-palette-blue100);

  .dalia-device-overview {
    &__cancel-configuration {
      width: auto !important;
    }

    span.iot-platform-ui-status-button__text {
      text-transform: inherit !important;
    }

    &-toolbar__mrt {
      &--pending,
      &--published {
        .iot-platform-ui-toolbar-button-TOGGLE_MRT:not(.toggle-on) {
          border: 1px solid;
          width: 28px !important;
          height: 28px !important;
        }
      }

      &--pending {
        .iot-platform-ui-toolbar-button-TOGGLE_MRT:not(.toggle-on) {
          @extend .bg-orange100;
          color: $orange700 !important;
        }
      }

      &--published {
        .iot-platform-ui-toolbar-button-TOGGLE_MRT:not(.toggle-on) {
          background-color: $blue100 !important;
          color: $blue !important;
        }
      }
    }
  }

  .iot-platform-ui-infos-display-card-property,
  iot-platform-ui-form-field,
  .iot-platform-ui-tab-group__link,
  .mat-mdc-tab-link,
  .mat-mdc-tab {
    .mat-mdc-form-field-infix {
      border-radius: 4px;
    }

    &.pending,
    &.published,
    &.deleted {
      .event-severity-display-container {
        background: $text-white !important;
      }

      span.mdc-tab__content:before {
        content: "";
        border: 1px solid #ffffff;
        height: 20px;
        width: 5px;
        border-radius: 3px;
        margin-right: 5px;
        position: relative;
        margin-left: -11px;
      }
    }

    &.pending {
      .iot-platform-ui-infos-display-card-property__value,
      .mat-mdc-form-field-infix {
        @extend .bg-orange100;
      }

      span.mdc-tab__content:before {
        @extend .bg-orange100;
      }
    }

    &.published {
      .iot-platform-ui-infos-display-card-property__value,
      .mat-mdc-form-field-infix {
        @extend .bg-blue100;
      }

      span.mdc-tab__content:before {
        @extend .bg-blue100;
      }
    }

    &.deleted {
      .iot-platform-ui-infos-display-card-property__value {
        @extend .bg-red300;
        color: $text-white;
      }

      span.mdc-tab__content:before {
        @extend .bg-red300;
      }
    }
  }

  .overview-card__section,
  .iot-platform-ui-overview-card-section__expansion-panel,
  .device-tank-list-tab__rolling-tank-duration {
    &.pending,
    &.published {
      .detail-popup-section-header {
        &:before {
          content: "";
          border: 1px solid #ffffff;
          height: 20px;
          width: 5px;
          border-radius: 3px;
          margin-right: 5px;
          position: relative;
        }
      }
    }

    &.pending {
      .detail-popup-section-header:before {
        @extend .bg-orange100;
      }
    }

    &.published {
      .detail-popup-section-header:before {
        @extend .bg-blue100;
      }
    }
  }
}
