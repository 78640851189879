@use "sass:map";

@mixin dalia-device-energy-mode-theme($theme) {
  $yellow300: map.get($theme, al-color-palette-yellow300);
  $green200: map.get($theme, al-color-palette-green200);
  $orange300: map.get($theme, al-color-palette-orange300);
  $red300: map.get($theme, al-color-palette-red300);
  $text-secondary: map.get($theme, al-color-text-primary);

  $yellow500: map.get($theme, al-color-palette-yellow500);
  $green400: map.get($theme, al-color-palette-green400);
  $orange400: map.get($theme, al-color-palette-orange400);
  $red400: map.get($theme, al-color-palette-red400);

  .iot-platform-ui-infos-display-card {
    &.device-energy-mode--fpm {
      .iot-platform-ui-infos-display-card__label {
        background: $green200;
        color: unset;
      }

      &.iot-platform-ui-infos-display-card--active, {
        border-color: $green400;
      }
    }

    &.device-energy-mode--eco1 {
      .iot-platform-ui-infos-display-card__label {
        background: $yellow300;
      }

      &.iot-platform-ui-infos-display-card--active, {
         border-color: $yellow500;
      }
    }

    &.device-energy-mode--eco2 {
      .iot-platform-ui-infos-display-card__label {
        background: $orange300;
      }

      &.iot-platform-ui-infos-display-card--active, {
        border-color: $orange400;
      }
    }

    &.device-energy-mode--psm {
      .iot-platform-ui-infos-display-card__label {
        background: $red300;
      }

      &.iot-platform-ui-infos-display-card--active, {
        border-color: $red400;
      }
    }
  }
}
